import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { 
	faSearch as faSearchLight, 
	faSync as FaSyncLight, 
	faEye, 
	faGlobe, 
	faTimes as faTimesLight, 
	faFileWord, 
	faFilePowerpoint, 
	faFileImage, 
	faFileSpreadsheet, 
	faFileArchive, 
	faFilePdf, 
	faFile, 
	faPlayCircle, 
	faArrowUp, 
	faEdit, 
	faSpinner, 
	faQuoteLeft, 
	faQuoteRight, 
	faUser as faUserLight, 
	faStar,
	faCircle as faCircleLight,
	faChevronRight as faChevronRightLight,
	faChevronLeft as faChevronLeftLight,
	faPhoneLaptop as faPhoneLaptopLight,
	faCheckSquare as faCheckSquareLight,
} from '@fortawesome/pro-light-svg-icons';
import { 
	faChevronRight as faChevronRightRegular, 
	faChevronLeft as faChevronLeftRegular, 
	faChevronUp as faChevronUpSolid, 
	faSearch as faSearchRegular, 
	faSync as faSyncRegular,
	faTimes as faTimesRegular, 
	faCircle as faCircleRegular,
	faCopy as faCopyRegular,
	faCheckSquare as faCheckSquareRegular,
	faPlus as faPlusRegular,
	faMinus as faMinusRegular
} from '@fortawesome/pro-regular-svg-icons';
import { faSearch as faSearchSolid, 
	faLink, 
	faDownload, 
	faCircle, 
	faBars,
	faSpinner as faSpinnerSolid,
	faUserGraduate as faUserGraduateSolid,
	faChurch as faChurchSolid,
	faSchool as faSchoolSolid,
	faHandsHelping as faHandsHelpingSolid,
	faHandHoldingHeart as faHandHoldingHeartSolid,
	faPlayCircle as faPlayCircleSolid,
	faAngleLeft as faAngleLeftSolid,
	faAngleRight as faAngleRightSolid,
	faAngleUp as faAngleUpSolid,
	faAngleDown as faAngleDownSolid,
	faPhone as faPhoneSolid,
	faClock as faClockSolid,
	faEnvelopeOpenText as faEnvelopeOpenTextSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { 
	faFacebookF, 
	faFacebookSquare, 
	faXTwitter, 
	faTwitterSquare, 
	faLinkedinIn, 
	faLinkedin, 
	faInstagram, 
	faYoutube
} from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

library.add(
	faCircleRegular,
	faSearchLight, 
	faSearchSolid,
	faSearchRegular,
	faSyncRegular,
	faFilePdf,
	faFileWord, 
	faFilePowerpoint, 
	faFileImage, 
	faFileSpreadsheet, 
	faFileArchive,
	faFile,
	faEye,
	faChevronRightRegular,
	faChevronLeftRegular,
	faLink,
	faDownload,
	faFacebookSquare,
	faTwitterSquare,
	faLinkedinIn,
	faLinkedin,
	faGlobe,
	faTimesLight,
	faTimesRegular,
	faPlayCircle,
	faArrowUp,
	faCircle,
	faInstagram,
	faEdit,
	faSpinner,
	faQuoteLeft, 
	faQuoteRight,
	faChevronUpSolid,
	faSpinnerSolid,
	faUserLight,
	FaSyncLight,
	faInstagram,
	faFacebookF,
	faXTwitter,
	faYoutube,
	faStar,
	faCircleLight,
	faUserGraduateSolid,
	faChurchSolid,
	faSchoolSolid,
	faHandsHelpingSolid,
	faHandHoldingHeartSolid,
	faPlayCircleSolid,
	faAngleLeftSolid,
	faAngleRightSolid,
	faAngleUpSolid,
	faAngleDownSolid,
	faPhoneSolid,
	faClockSolid,
	faEnvelopeOpenTextSolid,
	faCopyRegular,
	faCheckSquareRegular,
	faPlusRegular,
	faMinusRegular,
	faChevronRightLight,
	faPhoneLaptopLight,
	faCheckSquareLight,
	faChevronLeftLight,
	faBars
);
dom.i2svg();
dom.watch();